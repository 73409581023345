const PopupCard = ({ product, closePopup }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 p-4">
            <div className="flex flex-col items-center justify-center gap-2 rounded-xl bg-white p-2">
                <img
                    className=" w-full rounded-xl md:w-72"
                    src={product.imageUrl}
                    alt={product.product_name}
                />
                <h2 className=" text-lg font-semibold">
                    {product.product_name}
                </h2>
                <p className="">{product.product_slug}</p>
                <p className="text-lg font-semibold">
                    Price: ₹{product.product_price}
                </p>
                <button
                    className="w-full rounded-xl bg-[#78007a] p-2 text-white"
                    onClick={closePopup}
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default PopupCard
