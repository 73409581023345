import React, { useState } from 'react';
import QrScanner from 'react-qr-scanner';

const QRScannerComponent = ({ onScan, onError, setShowQRScanner, scannedQRData }) => {
  const [scannerError, setScannerError] = useState(null);

  const handleError = (err) => {
    console.error(err);
    setScannerError(err);
    onError(err);
  };

  const handleScan = (data) => {
    if (data) {
      console.log('Scanned data:', data.text);
      onScan(data);
    }
  };

  return (
    <div className="flex flex-col self-center items-center">
      <p className='text-xl my-4 font-sans font-bold'>Scan The QR!</p>
      {scannerError ? (
        <p>Error: {scannerError.message}</p>
      ) : (
        <>
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan} 
            style={{ width: '90%' }}
            constraints={{video:{ facingMode: "environment" }}}
          />

          <button onClick={() => setShowQRScanner(false)} className="bg-[#78007a] text-white font-semibold px-4 py-2 rounded-lg shadow-md mt-6">
            Go Back
          </button>
          {scannedQRData && <div className='text-center mt-6'>
              <p>if the a new window doesn't Pop-up.
                <br/> Please click on the link below</p>
              <a href={`${scannedQRData}`} className=' text-blue-600 mt-4'> {scannedQRData}</a>
            </div>
          }
        </>
      )}
    </div>
  );
};

export default QRScannerComponent;
