import React from 'react'
import { IoCall, IoClose, IoMenu } from 'react-icons/io5'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { FaCheck, FaHome } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPage } from '../actions'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MdGroups2 } from 'react-icons/md'

import { BsMenuButtonFill } from 'react-icons/bs'
const Checked = ({ index }) => {
    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(setCurrentPage(index)) // Set currentPage to 2 when clicked
    }
    return (
        <span
            onClick={handleClick} // Pass function reference to onClick
            className="rounded-full border-4 border-[#78007a] bg-[#78007a] p-1"
        >
            <span className="font-bold text-white">
                <FaCheck />
            </span>
        </span>
    )
}

const Unchecked = () => {
    return (
        <span className="rounded-full border-4 border-[#78007a] bg-[#D9D9D9] p-3"></span>
    )
}

const ProgressBar = () => {
    const currentPage = useSelector((state) => state.app.currentPage)

    return (
        <div className="flex items-center justify-center px-6 py-4 drop-shadow-lg">
            {currentPage === 0 && <></>}
            {currentPage === 1 && (
                <>
                    <Unchecked />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Unchecked />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Unchecked />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Unchecked />
                </>
            )}
            {(currentPage === 2 || currentPage === 4) && (
                <>
                    <Checked index={1} />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Unchecked />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Unchecked />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Unchecked />
                </>
            )}
            {(currentPage === 5 || currentPage === 6) && (
                <>
                    <Checked index={1} />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Checked index={2} />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Unchecked />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Unchecked />
                </>
            )}
            {currentPage === 7 && (
                <>
                    <Checked index={1} />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Checked index={2} />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Checked index={3} />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Unchecked />
                </>
            )}
            {currentPage === 8 && (
                <>
                    <Checked index={1} />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Checked index={2} />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Checked index={3} />
                    <div className="w-full border-t-4 border-[#78007a]"></div>
                    <Checked index={4} />

                </>
            )}
        </div>
    )
}

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isMoreOpen, setIsMoreOpen] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
        if (isMoreOpen) setIsMoreOpen(false) // Close More submenu when menu is toggled
    }

    const toggleSubMenu = () => {
        setIsMoreOpen(!isMoreOpen)
    }

    const handleNavigation = (path) => {
        navigate(path)
        toggleMenu() // Close the menu after navigation
    }

    const isActive = (path) => {
        return location.pathname === path
            ? 'border-2 border-transparent shadow-all-side p-2 rounded-md '
            : ''
    }

    // Define array of menu items with their paths and labels
    const menuItems = [
        { path: '/', label: 'Home', icons: <FaHome /> },
        { path: '/about', label: 'About Us', icons: <MdGroups2 /> },
        { path: '/contact', label: 'Contact Us', icons: <IoCall /> },
        { path: '/how-to', label: 'How To', icons: <FaHome /> },
    ]

    return (
        <nav className="z-50">
            <div className="bg-primary flex items-center justify-between p-2  text-3xl font-medium text-white">
                <h1>TheQuickCafe</h1>
                <div className="text-4xl cursor-pointer" onClick={toggleMenu}>
                    {isMenuOpen ? <IoClose /> : <IoMenu />}
                </div>
            </div>
            <ProgressBar />
            {/* Side Menu */}
            <div
                className={`fixed top-0 right-0 w-full md:w-[480px] bg-white shadow-lg transform ${
                    isMenuOpen
                        ? 'translate-x-0 lg:-translate-x-[33.5vw] md:-translate-x-1/3  '
                        : 'translate-x-full'
                } transition-transform duration-300 ease-in-out `}
            >
                <div className=" flex justify-between items-center p-2 shadow-md">
                    <h1 className="text-3xl font-semibold">TheQuickCafe</h1>
                    <div
                        className="text-4xl cursor-pointer"
                        onClick={toggleMenu}
                    >
                        {isMenuOpen ? <IoClose /> : <IoMenu />}
                    </div>
                </div>
                <div className="p-4 z-50">
                    <ul className="flex flex-col gap-4 text-left text-xl font-semibold text-gray-800">
                        {/* Map over menuItems array to generate menu items */}
                        {menuItems.map((item) => (
                            <li
                                onClick={() => handleNavigation(item.path)}
                                key={item.path}
                                className={`${isActive(item.path)} p-1 flex items-center gap-4`}
                            >
                                <span className="text-[#78007a]">
                                    {item.icons}
                                </span>
                                {item.label}
                            </li>
                        ))}
                        <li className="flex flex-col text-left p-1">
                            <button
                                onClick={toggleSubMenu}
                                className="flex items-center focus:outline-none gap-4"
                                key="more"
                            >
                                <span className="text-[#78007a]">
                                    <BsMenuButtonFill />
                                </span>
                                More{' '}
                                <span className="text-xl -ml-2">
                                    {!isMoreOpen ? (
                                        <IoIosArrowDown />
                                    ) : (
                                        <IoIosArrowUp />
                                    )}
                                </span>
                            </button>
                            {isMoreOpen && (
                                <ul className="mt-2 ml-10 flex flex-col gap-2 text-lg">
                                    <li
                                        onClick={() =>
                                            handleNavigation('/policies')
                                        }
                                        key="submenu1"
                                    >
                                        Policies
                                    </li>
                                    <li
                                        onClick={() =>
                                            handleNavigation('/terms')
                                        }
                                        key="submenu2"
                                    >
                                        Terms & Conditions
                                    </li>
                                </ul>
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
