import React, { useEffect, useState, useRef } from 'react';
import DisplayBox from '../Components/Dispense/DisplayBox';
import OrderDetails from '../Components/Dispense/OrderDetails';
import DispenseButton from '../Components/Dispense/DispenseButton';
import { webSocketUrl } from '../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../actions';

const Dispense = () => {
    const details = useSelector((state) => state.app.orderDetails);
    const dispatch = useDispatch()
    console.log(details);

    const [buttonTxt, setButtonTxt] = useState();
    const [buttonStatus, setButtonStatus] = useState(false); //true -->Disabled, false --> enabled
    const [queuePosition, setQueuePosition] = useState(0);
    const [tte, setTte] = useState(0);

    const ws = useRef(null);

    useEffect(() => {
        const wsConnect = () => {
          ws.current = new WebSocket(`${webSocketUrl}`);
          ws.current.onopen = function() {
              console.log('WebSocket connection opened');
              // Send initial message or start any process that depends on the WebSocket connection here
              getOrderStatus(details.oid, details.vmid);
          };
          ws.current.onclose = function() {
              console.log('WebSocket connection closed');
          };
            ws.current.onmessage = function(evt) {
                const obj = JSON.parse(evt.data);
                console.log(obj);
                switch (obj.Event) {
                    case "get-order":
                        if (obj.Payload.vm_status === "ONLINE"){
                            if (obj.Payload.quque_position === -5 ){
                                setQueuePosition(0)
                                setButtonTxt('Completed')
                                setButtonStatus(true)
                                clearInterval(interval)
                                ws.current.close();
                                dispatch(setCurrentPage(8))
                            }else if(obj.Payload.quque_position === -6){
                                setQueuePosition(0)
                                setButtonTxt('Cancelled')
                                clearInterval(interval)
                                setButtonStatus(true)
                                ws.current.close();
                                dispatch(setCurrentPage(8))
                            } else if (obj.Payload.quque_position === 1){
                                if (obj.Payload.exe_status === "executing") {
                                    setButtonTxt('Dispensing...')
                                    setButtonStatus(true)
                                    setQueuePosition(obj.Payload.quque_position)
                                } else if (obj.Payload.exe_status === "waiting_user_start"){
                                    setQueuePosition(obj.Payload.quque_position)
                                    setButtonTxt('Start Dispensing')
                                    setButtonStatus(false)
                                } else if (obj.Payload.exe_status === "user_did_press_start" ) {
                                    setButtonTxt('In Progress...')
                                    setButtonStatus(true)
                                    setQueuePosition(obj.Payload.quque_position)
                                } 
                            } else {
                                setButtonTxt('Waiting for your Turn...')
                                setButtonStatus(true)
                                setQueuePosition(obj.Payload.quque_position)
                                setTte(obj.Payload.tte)
                            }
                        }else{
                            setButtonTxt('Machine is Ofline')
                                setButtonStatus(true)
                        }

                        break;
                    case "start-despensing-order":
                        if (obj.error == null) {
                            setButtonTxt('Dispensing')
                            setButtonStatus(true)
                        }
                        break;
                }
            }
        };

        function getOrderStatus(orderid, vmid) {
            if (ws.current && ws.current.readyState === WebSocket.OPEN) {
                const paylod = `{"event":"get-order","payload":{"orderid":${orderid},"vmid":${vmid}}}`;
                // console.log(paylod);
                ws.current.send(paylod);
            } else {
                console.log('WebSocket connection is not open');
            }
        }

        wsConnect();

        const interval = setInterval(() => {
            getOrderStatus(details.oid, details.vmid);
          }, 1000);
      
          return () => clearInterval(interval);
       
    });


    function startDespensing(orderid) {
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          const paylod = `{"event":"start-despensing-order","payload":{"orderid":${orderid}}}`;
        //   console.log(paylod);
          ws.current.send(paylod);
      } else {
          console.log('WebSocket connection is not open');
      }
  }
  

    return (
        <div className="flex items-center justify-center bg-[#ffe6ff] mb-8">
            <div className="w-[90%] flex flex-col items-center max-w-md p-6 mt-4 bg-white rounded-xl shadow-md">
                {/* Display Blocks */}
                <div className="flex flex-col items-center w-[90%]">
                    <div className='flex flex-row justify-between w-full'>
                        <DisplayBox value={queuePosition} label="Your Queue Position" wd={24}/>
                        <DisplayBox value={tte} label="Estimated waiting time" wd={24} />
                    </div>
                    <DisplayBox value="Feature Dissabled" label="Item dispensed / Total Items" wd={"full"} />

                    {/* <DisplayBox value="8 / 11" label="Item dispensed / Total Items" wd={"full"} /> */}
                </div>

                {/* Order Details */}
                <OrderDetails oid={details.oid} pyid={details.pyid} name={"Quick Cafe"} id={details.vmid}/>

                {/* Dispense Button */}
                <DispenseButton
                    buttonTxt={buttonTxt}
                    handleClick={() => startDespensing(details.oid)}
                    disabled={buttonStatus}
                />

            </div>
        </div>
    );
};

export default Dispense;