export const dummySuggestions = [
  'Andy',
  'Angel',
  'Anne',
  'Anthony',
  'April',
  'Ariel',
  'Ava',
  'Avery',
  'Benjamin',
  'Bella',
  'Billy',
  'Bonnie',
  'Brandon',
  'Brianna',
  'Brooke',
  'Bryan',
  'Caleb',
  'Cameron',
  'Caroline',
  'Cassandra',
]

export const recentlyVisitedData = [
  {
    name: 'Place 1',
    status: true,
    wait: 2,
  },
  {
    name: 'Place 2',
    status: false,
    wait: 0,
  },
]

export const cart = [
  {
    item: 'Coffee',
    price: 20.0,
  },
  {
    item: 'Tea',
    price: 10.0,
  },
]
export const paymentMethods = [
  'PhonePe',
  'Paytm',
  'Gpay',
  'BHIM',
  'Amazon',
  'More',
]

export const policies = [
  {
    title: 'Privacy Policy',
    content: `We collect personal information from website visitors, including name, email address, IP address, and device information. We use this information to provide you with the services you request, communicate with you about your account, send you marketing emails, and improve our website. We may share your personal information with our service providers and marketing partners. We take steps to protect your personal information, including using secure servers to store your personal information and encryption to protect your personal information when it is transmitted over the internet. You have the following rights with respect to your personal information: you have the right to access your personal information, correct your personal information if it is inaccurate, delete your personal information, and object to the processing of your personal information. If you have any questions about our privacy policy, please contact us at info@thequickcafe.com.`,
  },
  {
    title: 'Terms of Use',
    content: `You agree not to use the website for any illegal or unauthorized purpose. You agree not to post or transmit any content that is defamatory, obscene, threatening, or otherwise harmful. You agree not to interfere with the operation of the website or the use of the website by other users. You agree to be responsible for any content that you post or transmit on the website.`,
  },
  {
    title: 'Refund Policy',
    content: `We offer a 100% satisfaction guarantee on all products sold on the website. If you are not satisfied with your purchase, you may return it for a full refund within 30 days of purchase.`,
  },
  {
    title: 'Shipping Policy',
    content: `We ship all orders within 2 business days of receipt. Shipping costs are calculated at checkout.`,
  },
  {
    title: 'Dispute Resolution Policy',
    content: `If you have any dispute with us, you agree to first try to resolve the dispute informally. If the dispute cannot be resolved informally, you agree to submit the dispute to binding arbitration.`,
  },
  {
    title: 'Governing Law',
    content: `This policy is governed by the laws in India.`,
  },
  {
    title: 'Changes to This Policy',
    content: `We may change this policy from time to time. Any changes will be posted on this page.`,
  },
  {
    title: 'Effective Date',
    content: `This policy is effective as of 01.04.2023.`,
  },
]

export const termsAndConditions = [
  {
    title: 'Acceptance of Terms and Conditions',
    content:
      'By using The Quick Cafe vending machine, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, you should not use The Quick Cafe vending machine.',
  },
  {
    title: 'Intellectual Property',
    content:
      'All content on The Quick Cafe vending machine, including but not limited to text, images, logos, and trademarks, is the property of The Quick Cafe or its licensors. You may not use any of this content without the express permission of The Quick Cafe.',
  },
  {
    title: 'Payment',
    content:
      'You may pay for products and services on The Quick Cafe vending machine using a variety of methods, including credit card, debit card, and PayPal. All payments are processed through a secure payment gateway.',
  },
  {
    title: 'Shipping',
    content:
      'Shipping charges will be applied to all orders. The shipping charges will be calculated based on the weight and destination of the order.',
  },
  {
    title: 'Returns',
    content:
      'You may return products purchased on The Quick Cafe vending machine for a refund within 30 days of the purchase date. The product must be in its original condition and packaging. In case payment is made without dispensation of beverage, then kindly share the payment details on our registered email id or mobile number. The same would be refunded back within 7 days of the payment.',
  },
  {
    title: 'Warranty',
    content:
      "All products sold on The Quick Cafe vending machine are warranted to be free from defects in materials and workmanship for a period of one year from the date of purchase. If a product is found to be defective, it will be repaired or replaced at The Quick Cafe's discretion.",
  },
  {
    title: 'Limitation of Liability',
    content:
      'The Quick Cafe will not be liable for any damages, including but not limited to direct, indirect, incidental, consequential, or punitive damages, arising out of or in connection with the use of The Quick Cafe vending machine.',
  },
  {
    title: 'Miscellaneous',
    content:
      'These terms and conditions may be changed at any time without notice. The current version of the terms and conditions will always be posted on The Quick Cafe vending machine.',
  },
  {
    title: 'Governing Law',
    content:
      'These terms and conditions will be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these terms and conditions will be subject to the exclusive jurisdiction of the courts of Nagpur (M.H)',
  },
  {
    title: 'Additional Information',
    content: [
      'The Quick Cafe vending machine accepts both cash and credit cards.',
      'The Quick Cafe vending machine is stocked with a variety of drinks and snacks, including coffee, tea, soda, candy, and chips.',
      'The Quick Cafe vending machine is located in a convenient location, making it easy to get a quick snack or beverage on the go.',
      'The Quick Cafe vending machine is monitored by security cameras, so you can be sure that your purchase is safe and secure.',
      'We hope you enjoy using The Quick Cafe vending machine!',
    ].join(' '),
  },
]

export const vendingMachineSteps = [
  {
    step: 1,
    title: 'Search for the Vending Machine',
    description:
      'Navigate to the vending machine website or application. Look for the search feature, which may be a search bar or a browse option. Use this feature by either entering the name of the product you want or browsing through the available categories. Categories might include snacks, beverages, and healthy options. The interface is user-friendly, often with icons and images to assist you in making your selection.',
    image: 'images/step1_search.png',
  },
  {
    step: 2,
    title: 'Select Your Product',
    description:
      "Once you find your desired product, click on its icon or image to bring up more detailed information. This page typically shows the price, nutritional facts, and other relevant details about the product. Confirm your selection by clicking the 'Select' or 'Add to Cart' button, which will add the item to your virtual shopping cart. You can usually see a small cart icon that updates with your selections. Make sure to check if any promotions or discounts are applied at this stage.",
    image: 'images/step2_select_product.png',
  },
  {
    step: 3,
    title: 'Proceed to the Order Page',
    description:
      "After you have selected all the products you want, navigate to the order page by clicking the 'Checkout' or 'Proceed to Order' button. This page will display a summary of your order, including the items selected, their quantities, and the total cost. Review this summary carefully to ensure that all the details are correct and that you haven't missed anything. You might have the option to remove items or change quantities directly from this page. Once satisfied, confirm your order to move on to the payment process.",
    image: 'images/step3_order_page.png',
  },
  {
    step: 4,
    title: 'Initialize the Payment',
    description:
      "On the order page, look for the 'Pay Now' or similar button to initiate the payment process. You will be prompted to choose your preferred payment method from a list of options. Common payment methods include credit or debit cards, mobile payment apps like Apple Pay or Google Pay, or a preloaded vending card. The application will guide you through the payment steps, which may include entering your card details or scanning a QR code. Ensure that your payment method is ready and valid to avoid any delays or errors.",
    image: 'images/step4_initialize_payment.png',
  },
  {
    step: 5,
    title: 'Pay with Your Choice of Payment Method',
    description:
      "Follow the instructions to complete the payment using your selected method. If you're paying by card, you might need to enter your card details or use a digital wallet. For mobile payments, open the relevant app on your phone and scan the QR code displayed on the website or application. The application will usually show a confirmation message once the payment is successfully processed. Keep an eye out for any prompts asking you to confirm the payment on your device.",
    image: 'images/step5_payment_method.png',
  },
  {
    step: 6,
    title: 'Wait in Queue and Dispense',
    description:
      'After the payment is confirmed, the vending machine will begin preparing your order. You might see a message indicating that your product is being dispensed. Wait for a few moments as the machine retrieves your item from its storage and prepares it for pickup or delivery. Once your order is ready, you will receive a notification or update on the website or application. If you opted for pickup, you can then collect your product from the designated location. If you chose delivery, sit back and wait for your purchase to arrive. Check that you have received the correct item before enjoying your purchase.',
    image: 'images/step6_dispense.png',
  },
]
