import React from 'react'
import { policies } from '../../Data/Data'
import { useEffect } from 'react'
import { setCurrentPage } from '../../actions'
import { useDispatch } from 'react-redux'

const PoliciesPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setCurrentPage(0))
    }, [])

    return (
        <section className="bg-[#FFE6FF] pb-12">
            <div className="container mx-auto px-4 text-left">
                <h2 className="text-4xl font-bold text-center text-[#78007a] mb-8">
                    The Quick Café Policies
                </h2>
                <div className="text-gray-700">
                    {policies.map((policy, index) => (
                        <div
                            key={index}
                            className="bg-white shadow-md rounded-lg p-8 mb-6"
                        >
                            <h3 className="text-2xl font-semibold text-[#78007a] mb-4">
                                {policy.title}
                            </h3>
                            <p className="mb-6">{policy.content}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default PoliciesPage
