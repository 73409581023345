import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { FaCheck, Fa1, Fa2, Fa3, Fa4, Fa5, Fa6 } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from '../actions'
import { vendingMachineSteps } from '../Data/Data'

const HowTo = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setCurrentPage(0))
  }, [])
  const [step, setStep] = useState(1)

  return (
    <div className="bg-[#FFE6FF]">
      <StepBar setStep={setStep} step={step} />
      <div className="bg-white shadow-md rounded-lg p-8 m-6 text-left">
        <h3 className="text-2xl font-semibold text-[#78007a] mb-4">
          {vendingMachineSteps[step - 1].title}
        </h3>
        <p className="mb-6">
          {vendingMachineSteps[step - 1].description}
        </p>
      </div>
    </div>
  )
}

export default HowTo

const StepBar = ({ setStep, step }) => {
  const steps = [
    { number: 1, icon: Fa1 },
    { number: 2, icon: Fa2 },
    { number: 3, icon: Fa3 },
    { number: 4, icon: Fa4 },
    { number: 5, icon: Fa5 },
    { number: 6, icon: Fa6 },
  ]

  return (
    <div className="px-4">
      <div className="flex items-center">
        {steps.map(({ number, icon: Icon }, index) => (
          <React.Fragment key={number}>
            <span
              onClick={() => setStep(number)}
              className={`flex justify-center items-center text-white rounded-full border-[#78007a] bg-[#78007a] border-2 p-2 cursor-pointer `}
            >
              {step >= number ? <FaCheck /> : <Icon />}
            </span>
            {index < steps.length - 1 && (
              <div className="w-full border-t-4 border-[#78007a]"></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
