import CardAddRemoveButton from './CartButton'
const ProductCard = ({ product, openPopup, cart, setCart }) => {
    return (
        <div className="flex flex-row items-center justify-between gap-2 rounded-xl bg-white p-2 shadow-xl w-full">
            <img
                onClick={() => openPopup(product.id)}
                className="w-28 rounded-xl"
                src={product.imageUrl} // Provide the URL for the product image
                alt={product.product_name}
            />
            <div
                onClick={() => openPopup(product.id)}
                className="flex flex-col justify-between text-left"
            >
                <h2 className="text-lg font-semibold">
                    {product.product_name}
                </h2>
                <p className="text-sm">{product.product_slug}</p>
            </div>
            <div className="flex flex-col items-center text-[#78007A]">
                <p className="text-lg">₹{product.product_price}</p>
                <CardAddRemoveButton
                    product={product}
                    cart={cart}
                    setCart={setCart}
                />
            </div>
        </div>
    )
}

export default ProductCard
