import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import Payment from './Payment'
import Products from './Products'
import { setID, setCurrentPage } from '../actions'
import { useEffect } from 'react'
import PaymentStats from './PaymentStats'

const Order = () => {
    const { vmid } = useParams()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    console.log(searchParams);

    console.log(vmid)
    const dispatch = useDispatch()

    const onReload= () => {
        dispatch(setID(vmid))
        if(searchParams == 0)
            dispatch(setCurrentPage(1))
        else
            dispatch(setCurrentPage(3))
    }

    useEffect(() => {
        onReload()
    }, []);
    
    

    const state = useSelector((state) => state.app.currentPage)
    return (
        <>
            {state === 1 && <Products />}
            {state === 2 && <Payment />}
            {state >= 3 && <PaymentStats />}
        </>
    )
}

export default Order
