import { useDispatch } from 'react-redux'
import { termsAndConditions } from '../../Data/Data'
import React from 'react'
import { setCurrentPage } from '../../actions'
import { useEffect } from 'react'

const TermsPage = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setCurrentPage(0))
    }, [])
    return (
        <section className="bg-[#FFE6FF] pb-12">
            <div className="container mx-auto px-4 text-left">
                <h2 className="text-3xl font-bold text-center text-[#78007a] mb-8">
                    Terms and Conditions
                </h2>
                <div className="text-gray-700">
                    {termsAndConditions.map((terms, index) => (
                        <div
                            key={index}
                            className="bg-white shadow-md rounded-lg p-8 mb-6"
                        >
                            <h3 className="text-2xl font-semibold text-[#78007a] mb-4">
                                {terms.title}
                            </h3>
                            <p className="mb-6">{terms.content}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default TermsPage
