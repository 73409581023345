import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setID, setCurrentPage } from '../../actions'
import { searchApi } from '../../services/api'
import { useNavigate } from 'react-router-dom'
const SearchComponent = ({ setSearchFocus, inputRef, searchFocus }) => {
    const navigate = useNavigate()
    const [suggestions, setSuggestions] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [loading, setLoading] = useState(false)

    // Initialize dispatch to allow state changes in Redux
    const dispatch = useDispatch()

    // Function to fetch suggestions from API
    const fetchSuggestions = async (query) => {
        setLoading(true)
        try {
            const response = await axios.get(searchApi, {
                params: {
                    phrase: query,
                },
            })

            if (response.data && response.data.Data) {
                setSuggestions(response.data.Data)
            } else {
                setSuggestions([])
            }
        } catch (error) {
            console.error('Failed to fetch suggestions:', error)
            setSuggestions([])
        } finally {
            setLoading(false) // Set loading state to false
        }
    }

    // Event handler for input changes
    const handleChange = (e) => {
        const query = e.target.value
        setInputValue(query)
    }

    // Use effect to call API after a delay when input value changes
    useEffect(() => {
        const timer = setTimeout(() => {
            if (inputValue.trim() !== '') {
                fetchSuggestions(inputValue)
            } else {
                setSuggestions([])
            }
        }, 1000)

        return () => clearTimeout(timer)
    }, [inputValue])

    // Clear suggestions when the search box loses focus
    useEffect(() => {
        if (!searchFocus) {
            setSuggestions([])
        }
    }, [searchFocus])

    // Handle clicks outside the search box
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                setSuggestions([])
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [inputRef, setSearchFocus])

    // Event handler for suggestion click
    const handleSuggestionClick = (suggestion) => {
        // Dispatch the action to set the ID of the clicked suggestion
        dispatch(setID(suggestion.id))
        dispatch(setCurrentPage(1))
        navigate(`/vendingmachine/${suggestion.id}`)
    }

    return (
        <div className="relative rounded-xl p-4 mb-8 h-60 z-10 flex flex-col justify-center border-2 border-[#78007a]">
            <input
                ref={inputRef}
                type="text"
                placeholder="Search Vending Machine..."
                className="p-3 w-full bg-transparent transition-transform outline-none border-b-2 border-[#78007a] focus:-translate-y-20 focus:border-b-2"
                onFocus={() => setSearchFocus(true)}
                onBlur={() => setSearchFocus(false)}
                onChange={handleChange}
                value={inputValue}
            />
            {searchFocus && (
                <div className="absolute top-full left-0 right-0 z-10 overflow-y-auto max-h-40 -translate-y-40">
                    {loading ? (
                        <div className="p-2">Loading...</div> // Removed border class
                    ) : (
                        suggestions.map((suggestion, index) => (
                            <div
                                key={index}
                                className="p-2 border-b border-gray-300 cursor-pointer"
                                onClick={() =>
                                    handleSuggestionClick(suggestion)
                                }
                            >
                                {suggestion.name}
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    )
}

export default SearchComponent
