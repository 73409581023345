import React from 'react'
import { useEffect } from 'react'
import {
    FaPhoneAlt,
    FaMapMarkerAlt,
    FaEnvelope,
    FaFacebookF,
    FaTwitter,
    FaLinkedinIn,
    FaInstagram,
} from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from '../actions'

const Contact = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setCurrentPage(0))
    }, [])
    return (
        <section className="bg-[#FFE6FF] pb-12">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl font-bold text-[#78007a] mb-8">
                    Contact Us
                </h2>
                <div className="flex flex-col gap-8">
                    <div className="bg-white shadow-md rounded-lg p-6 text-left">
                        <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                            Get in Touch
                        </h3>
                        <div className="mb-6">
                            <a
                                className="flex items-center text-gray-700 mb-2"
                                href="tel:8421586845"
                            >
                                <FaPhoneAlt className="text-[#78007a] mr-2 size-5 " />{' '}
                                <div className="flex flex-col">
                                    {' '}
                                    <p>Contact : Mr. Chirag Sanghvi</p>
                                    <p>Customer Care : +91 8421586845</p>
                                </div>
                            </a>
                            <p className="flex items-center text-gray-700 mb-2">
                                <FaMapMarkerAlt className="text-[#78007a] mr-2 size-8" />{' '}
                                Corporate Office: Plot no.2, Pratap Nagar, Ring
                                Road, Nagpur – 440022
                            </p>
                            <p className="flex items-center text-gray-700 mb-2 ">
                                <FaEnvelope className="text-[#78007a] mr-2 size-5" />{' '}
                                Email: info@thequickcafe.com
                            </p>
                        </div>
                        <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                            Follow Us
                        </h3>
                        <div className="flex space-x-4">
                            <a
                                href="/"
                                className="text-[#78007a] "
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaFacebookF size={24} />
                            </a>
                            <a
                                href="/"
                                className="text-[#78007a]"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaTwitter size={24} />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/quickcafe/about/"
                                className="text-[#78007a]"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaLinkedinIn size={24} />
                            </a>

                            <a
                                href="/"
                                className="text-[#78007a]"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaInstagram size={24} />
                            </a>
                        </div>
                    </div>
                    {/* <div className="bg-white shadow-md rounded-lg p-6">
                        <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                            Contact Form
                        </h3>
                        <form>
                            <div className="mb-4">
                                <label
                                    className="block text-gray-700 text-sm font-bold mb-2"
                                    htmlFor="name"
                                >
                                    Name
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="name"
                                    type="text"
                                    placeholder="Your Name"
                                />
                            </div>
                            <div className="mb-4">
                                <label
                                    className="block text-gray-700 text-sm font-bold mb-2"
                                    htmlFor="email"
                                >
                                    Email
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="email"
                                    placeholder="Your Email"
                                />
                            </div>
                            <div className="mb-4">
                                <label
                                    className="block text-gray-700 text-sm font-bold mb-2"
                                    htmlFor="message"
                                >
                                    Message
                                </label>
                                <textarea
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="message"
                                    rows="4"
                                    placeholder="Your Message"
                                ></textarea>
                            </div>
                            <div className="flex items-center justify-between">
                                <button
                                    className="bg-[#78007a] hover:bg-[#5a005a] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button"
                                >
                                    Send Message
                                </button>
                            </div>
                        </form>
                    </div> */}
                </div>
                <div className="mt-8">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.8884792037657!2d79.079719615417!3d21.145800985926394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c0e0b2a80aeb%3A0x8a9c1b6df255c7f9!2sPratap%20Nagar%2C%20Nagpur%2C%20Maharashtra%20440022%2C%20India!5e0!3m2!1sen!2sus!4v1623930528453!5m2!1sen!2sus"
                        width="100%"
                        height="450"
                        allowFullScreen=""
                        loading="lazy"
                        className="border-0 rounded-lg shadow-md"
                        title="Google Maps Location"
                    ></iframe>
                </div>
            </div>
        </section>
    )
}

export default Contact
