import axios from 'axios'
import React, { useEffect, useState } from 'react'

import PopupCard from '../Components/PopupCard'
import ProductCard from '../Components/ProductCard'
import { inventoryApi, statusApi } from '../services/api'
import OrderNowButton from '../Components/OrderNowButton'
import { useParams, useNavigate } from 'react-router-dom'


const Products = () => {
    // const machineID = useSelector((state) => state.app.machineID)
    const navigate = useNavigate()
    const { vmid } = useParams()
    console.log('Machine ID :', vmid)
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [cart, setCart] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const statusResponse = await axios.get(statusApi(vmid))
                if (statusResponse.data.Data.status === 'ONLINE') {
                    const inventoryResponse = await axios.get(
                        inventoryApi(vmid)
                    )
                    setProducts(inventoryResponse.data.Data)
                    console.log(
                        'Fetched inventory:',
                        inventoryResponse.data.Data
                    )
                } else {
                    navigate('/offlineMachine')
                }
            } catch (error) {
                console.error('Error fetching data:', error)
                // Handle errors here
            }
        }

        fetchData()
        // eslint-disable-next-line
    }, [])
    // Fetch inventory when machineID changes

    const openPopup = (productId) => {
        setSelectedProduct(productId)
    }

    const closePopup = () => {
        setSelectedProduct(null)
    }

    return (
        <div className="h-full flex flex-col items-center">
            <div className="flex flex-col gap-3 overscroll-x-auto px-2 w-full">
                {products.map((product) => (
                    <ProductCard
                        key={product.id}
                        product={product}
                        openPopup={openPopup}
                        cart={cart}
                        setCart={setCart}
                    />
                ))}
            </div>

            {selectedProduct !== null && (
                <PopupCard
                    product={products.find(
                        (product) => product.id === selectedProduct
                    )}
                    closePopup={closePopup}
                />
            )}
            <OrderNowButton cart={cart} vmid={vmid} />
        </div>
    )
}

export default Products
