import { useState } from 'react'

const CardAddRemoveButton = ({ product, cart, setCart }) => {
    const [quantity, setQuantity] = useState(
        cart[product.id] ? cart[product.id].quantity : 0
    )

    const handleAdd = () => {
        const newQuantity = quantity + 1
        setQuantity(newQuantity)
        setCart((prevCart) => ({
            ...prevCart,
            [product.id]: {
                ...product,
                quantity: newQuantity,
            },
        }))
        console.log(cart)
    }

    const handleRemove = () => {
        if (quantity > 0) {
            const newQuantity = quantity - 1
            setQuantity(newQuantity)
            if (newQuantity === 0) {
                const { [product.id]: _, ...rest } = cart
                setCart(rest)
            } else {
                setCart((prevCart) => ({
                    ...prevCart,
                    [product.id]: {
                        ...product,
                        quantity: newQuantity,
                    },
                }))
            }
        }
    }

    return (
        <div className="flex flex-row gap-5 rounded-full bg-[#FFE6FF] p-1 px-3 shadow-lg">
            <button onClick={handleRemove}>-</button>
            {quantity}
            <button onClick={handleAdd}>+</button>
        </div>
    )
}

export default CardAddRemoveButton
