import React from 'react';
import { FaSearch, FaExpand } from 'react-icons/fa';

const HomeButtons = ({ onClickSearch, onClickShowQRScanner }) => {
  return (
    <div className="flex justify-center mb-6">
      <button onClick={onClickSearch} className="bg-transparent p-2 mr-4 border border-purple-700 rounded-lg">
        <FaSearch className="text-xl" />
      </button>
      <button onClick={onClickShowQRScanner} className="bg-transparent p-2 border border-purple-700 rounded-lg">
        <FaExpand className="text-xl" />
      </button>
    </div>
  );
};

export default HomeButtons;
