import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentProcessing from './PaymentProcessing';
import PaymentSuccess from './PaymentSuccess';
import { useLocation } from 'react-router-dom';
import { setOrderDetails, setCurrentPage } from '../actions';
import axios from 'axios';
import SomethingWW from './SomethingWW';
import Dispense from './Dispense';
import { orderApi } from '../services/api';


const PaymentStats = () => {
  const details = useSelector((state) => state.app.orderDetails);
  const [orderID, setOrderID] = useState();
  const location = useLocation();
  const dispatch = useDispatch();

  const fetchOrderStatus = async (oid) => {
    try {
      const response = await axios.get(orderApi(oid));
      console.log(response.data);
      const delivery_status = response.data.Data.delivery_status;
      if (delivery_status === 'pending') {
        console.log("Changing the Page to 4");
        dispatch(setCurrentPage(4));
      }
      if (delivery_status === 'payment_done') {
        dispatch(setCurrentPage(5));
      }
    } catch (error) {
      console.error('Error fetching order status:', error);
    }
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const payloadBase64 = queryParams.get('payload');

    if (payloadBase64) {
      const payloadJson = atob(payloadBase64);

      try {
        const payloadObj = JSON.parse(payloadJson);
        const { cid, oid, pyid, vmid } = payloadObj;
        console.log(payloadObj);

        setOrderID(oid);
        dispatch(setOrderDetails({
          'cid': cid,
          'oid': oid,
          'pyid': pyid,
          'vmid': vmid,
        }));
        console.log('fetching the API');
        fetchOrderStatus(oid);
      } catch (error) {
        console.error('Error parsing payload JSON:', error);
      }
    }
  }, [location.search]);



  const state = useSelector((state) => state.app.currentPage);
  console.log('Current Page:', state);

  return (
    <>
      {state === 4 && <PaymentProcessing />}
      {state === 5 && <PaymentSuccess />}
      {state === 6 && <SomethingWW />}
      {(state === 7 || state === 8) && <Dispense />}
    </>
  );
};

export default PaymentStats;
