import React from 'react'
// import { useSelector } from 'react-redux'
import Navbar from './Components/Navbar'
import Home from './Pages/Home'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import OfflineMachine from './Pages/OfflineMachine'
import Order from './Pages/Order'
import About from './Pages/About'
import Contact from './Pages/Contact'
import Policies from './Pages/More/Policies'
import TermsPage from './Pages/More/Term'
import HowTo from './Pages/HowTo'

function App() {
    // Use Redux hooks to access state and dispatch actions

    return (
        <div className="flex h-screen flex-col">
            <Router>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/vendingmachine/:vmid" element={<Order />} />
                    {/* <Route
                        path="/vendingmachine/:vmid?ordered=:orderid"
                        element={<Payment />}
                    /> */}
                    <Route
                        path="/offlineMachine"
                        element={<OfflineMachine />}
                    />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/policies" element={<Policies />} />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/how-to" element={<HowTo />} />
                </Routes>
            </Router>
        </div>
    )
}

export default App
