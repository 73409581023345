import React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from '../actions'

const About = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setCurrentPage(0))
    }, [])
    return (
        <section className=" h-[200vh] bg-[#FFE6FF]  pb-4">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl font-bold text-center text-[#78007a] pb-4">
                    About Us
                </h2>

                <div className="bg-white shadow-lg rounded-lg p-6 text-gray-700 flex flex-col gap-4 text-left font-medium">
                    <p>
                        The Quick Café is a startup founded by a young group of
                        school friends who aim to provide technology-driven
                        solutions for dispensing beverages from vending
                        machines.
                    </p>
                    <p>
                        Our technology allows users to pay for drinks using a
                        variety of methods, including credit and debit cards, as
                        well as mobile wallets. This makes it easy for people to
                        get their favorite drinks quickly and conveniently,
                        without having to fumble with coins or cards.
                    </p>
                    <p>
                        The Quick Café's technology is also highly secure, using
                        the latest encryption standards to protect users'
                        financial information. We are committed to providing a
                        safe and secure payment experience for all of our
                        customers.
                    </p>
                    <p>
                        In addition to our payment integration technology, we
                        offer a variety of other features that make our solution
                        a valuable tool for businesses. These features include:
                    </p>
                    <ul className="list-disc list-inside text-left pl-10 gap-2 flex flex-col">
                        <li>
                            Real-time inventory tracking: Our software tracks
                            the inventory of each vending machine, so businesses
                            can always know how much of each drink is available.
                        </li>
                        <li>
                            Fraud detection: Our software uses machine learning
                            to detect fraudulent transactions, helping
                            businesses to protect themselves from financial
                            loss.
                        </li>
                        <li>
                            Reporting: Our software provides detailed reports on
                            sales, inventory, and fraud, so businesses can track
                            their performance and make informed decisions.
                        </li>
                    </ul>
                    <p>
                        The Quick Café is a valuable tool for businesses that
                        want to provide their customers with a convenient and
                        secure way to purchase drinks from vending machines. Our
                        technology is easy to use and highly secure, and it
                        offers a variety of features that can help businesses to
                        improve their bottom line.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default About
