import React, { useEffect, useState } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../actions';
import { orderApi, paymentApi } from '../services/api';


const PaymentProcessing = () => {
  const orderInfo = useSelector((state) => state.app.orderDetails)
  const dispatch = useDispatch()
  console.log(orderInfo);
  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentDt, setPaymentDt] = useState(null);


  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(orderApi(orderInfo.oid));
        setOrderDetails(response.data.Data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    fetchOrderDetails();
  }, []); // Empty dependency array to runs only once after mount

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.get(paymentApi(orderInfo.pyid))
        setPaymentDt(response.data.Data);
        console.log("Calling Payment API");
        console.log(response.data);
      }catch (error) {
        console.error('Error fetching payment details:', error);
      }
    }

    const interval = setInterval(() => {
      fetchPaymentDetails();
    }, 2000);

    return () => clearInterval(interval);
  })

  useEffect(() => {
    if (paymentDt) {
      switch (paymentDt.pg_status) {
        case 'PAYMENT_INITIATED':
          // Do nothing, stay on the same page
          // dispatch(setCurrentPage(5));
          console.log("Payment Initiated");
          break;
        case 'PAYMENT_SUCCESS':
          console.log("Payment Success");
          dispatch(setCurrentPage(5));
          break;
        case 'PAYMENT_FAILED':
          console.log("Payment Failed");
          dispatch(setCurrentPage(6));
          break;
        default:
          console.error('Invalid pg_status:', paymentDt.pg_status);
      }
    }
  }, [paymentDt, dispatch]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col items-center mt-12 bg-pink-100">
        <div className="bg-[#78007a] rounded-full w-24 h-24 flex items-center justify-center mx-auto mb-4">
          <ImSpinner8 className="text-white w-12 h-12 animate-spin" />
        </div>
        <p className="text-gray-700 mt-5 w-[94%]">Loading...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center mt-12 bg-pink-100">
      <div className="bg-[#78007a] rounded-full w-24 h-24 flex items-center justify-center mx-auto mb-4">
        <ImSpinner8 className="text-white w-12 h-12 animate-spin" />
      </div>

      <div className="text-center bg-white p-8 rounded-lg shadow-md w-[95%] max-w-md mt-12">
        <p className="text-gray-700 font-bold mb-4 text-xl text-left">Payment Detail</p>
        {orderDetails && (
          <div className="text-left text-gray-700 mb-4">
            <div className="flex justify-between">
              <p>Order No.</p>
              <p>{orderDetails.id}</p>
            </div>
            <div className="flex justify-between">
              <p>Total</p>
              <p>₹{orderDetails.amount.toFixed(2)}</p>
            </div>
            <div className="flex justify-between">
              <p>Date & Time</p>
              <p>{new Date(orderDetails.created).toLocaleString()}</p>
            </div>
            <div className="flex justify-between">
              <p>Payment Method</p>
              <p>PHONEPE</p>
            </div>
            <div className="flex justify-between">
              <p>Name</p>
              <p>Guest User</p>
            </div>
            <div className="flex justify-between">
              <p>Email</p>
              <p>guestuser@gmail.com</p>
            </div>
          </div>
        )}
      </div>

      <p className="text-gray-700 mt-5 w-[94%]">
        Processing Payment ... <br />
        A receipt will be sent directly to the email.
        You will be automatically redirected to the Next page.
      </p>
    </div>
  );
};

export default PaymentProcessing;
