import React from 'react'
import { recentlyVisitedData } from '../../Data/Data'

const RecentlyVisited = () => {
    return (
        <>
            <h2 className="text-xl font-bold mb-4 text-left">
                Recently Visited
            </h2>
            {recentlyVisitedData.map((place, index) => (
                <div
                    key={index}
                    className="border border-[#78007a] h-auto rounded-xl p-4 mb-2 flex items-center"
                >
                    <div
                        className={`h-4 w-4 rounded-full ${place.status ? 'bg-green-500' : 'bg-red-500'}`}
                    ></div>
                    <div className="flex flex-col ml-2 text-left">
                        <p className="font-bold">{place.name}</p>
                        <p>
                            Status: {place.status ? 'Available' : 'Unavailable'}
                        </p>
                        <p>Wait: {place.wait} (person)</p>
                    </div>
                </div>
            ))}
        </>
    )
}

export default RecentlyVisited
