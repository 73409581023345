import React from 'react';

const DispenseButton = ({ buttonTxt, handleClick, disabled }) => {
    return (
        <div className='flex flex-col items-center'>
            <div className="mt-3 w-60 rounded-xl border-t-4 border-[#78007a] center"></div>
            <button
                className={`bg-[#78007a] text-white py-2 px-4 rounded-lg text-lg w-[20rem] my-6 ${disabled ? ' bg-gray-400 text-gray-600' : 'bg-[#78007a] text-white'}`}
                onClick={handleClick}
                disabled={disabled}
            >
                {buttonTxt}
            </button>
        </div>
    );
};

export default DispenseButton;
