import React from 'react'

const OrderDetails = ({oid, pyid, name, id}) => {
  return (
    <div className="self-center text-left text-gray-700 mb-4 mt-6 w-[90%]">
            <div className="flex justify-between">
              <p>Order Id:</p>
              <p>{oid}</p>
            </div>
            <div className="flex justify-between">
              <p>Payment Id:</p>
              <p>{pyid}</p>
            </div>
            <p className='mt-4'><b>Vending Machine</b></p>
            <div className="flex justify-between">
              <p>Name:</p>
              <p>{name}</p>
            </div>
            <div className="flex justify-between">
              <p>Id:</p>
              <p>{id}</p>
            </div>
    </div>
  )
}

export default OrderDetails