import { useDispatch } from 'react-redux'
import { setCart, setCurrentPage, setRedirect, setQueryParams } from '../actions'
import TotalSummary from './TotalSummary'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useState } from 'react'
import { checkoutApi } from '../services/api'
import * as DeviceInfo from '../Utili/DeviceInfo'

const OrderNowButton = ({ cart, vmid }) => {

    const deviceInfo = DeviceInfo.getDeviceInfo()

    const [responseMessage, setResponseMessage] = useState(null);

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cartArray = Object.values(cart)

    const calculateTotal = (cartArray) => {
        return cartArray.reduce((total, item) => total + item.product_price * item.quantity, 0);
    };

    const handleOrder = () => {
        // Dispatch actions to set cart and current page
        console.log(cart)

        dispatch(setCart(cart))
        dispatch(setCurrentPage(2))

        handleButtonClick();
    }
    console.log("cartArray: ",cartArray)
    
    const handleButtonClick = async () => {
        try {
            const items = cartArray.map((item) => ({
                invid: item.id,
                count: item.quantity,
                itemName: item.product_name,
                price: item.product_price,
                subtotal: item.product_price * item.quantity,
            }));

            const requestData = {
                cart_data: {
                    items,
                    total: calculateTotal(cartArray),
                    uniqueitemcount: cartArray.length,
                },
                vending_machine: {
                    id: cartArray[0].vendingmachines_id,
                },
                payment_info: {
                    pg: 'PHONEPE',//'TQCTESTPG',
                    mobile: '',
                    ppepg: {
                        payment_instrument_type: 'OTHERINSTRUMENTS',
                    },
                },
            };

            const response = await axios.post(
                checkoutApi,
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-DEVICEBROWSER': 'MOB_CHROME', //deviceInfo.browser, 
                        'X-DEVICEOS': 'ANDROID',//deviceInfo.os,
                    },
                }
            );
            //'MOB_CHROME',
            //'ANDROID',

            setResponseMessage(response.data);
            console.log(response.data);
            const redirectUrl = response.data.Data.PayRsponse.data.instrumentResponse.redirectInfo.url ;
            const queryURL = getQuery(response.data.Data.Redirect.url)
            console.log(queryURL);

            dispatch(setRedirect(redirectUrl))
            dispatch(setQueryParams(queryURL))
        } catch (error) {
            console.error('Error processing payment:', error);
        }
    };

    const getQuery = (url) => {
        const params = "";
        const urlParts = url.split('?');
        if (urlParts.length > 1) {
            const queryString = urlParts[1];
            return queryString
        }
        return params;
    };

    const isCartEmpty = cartArray.length === 0;

    return (
        <div className="flex flex-col justify-center items-center fixed bottom-2 w-full">
            <TotalSummary cart={cart} />
            <button
                className={`w-[20rem] rounded-lg ${isCartEmpty ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-[#78007a] text-white'} p-2 text-lg mt-4`}
                onClick={handleOrder}
                disabled={isCartEmpty}
            >
                Order Now
            </button>
        </div>
    )
}

export default OrderNowButton
