const CartSummary = ({ cart, summaryItems }) => {
    console.log(cart)
    return (
        <div className="w-full rounded-xl bg-white p-4 text-left shadow-xl">
            <div className="h-60">
                <h2 className="text-2xl font-bold">Summary</h2>
                <div>
                    {cart.map((product, index) => (
                        <div key={index} className="flex justify-between">
                            <p>{product.product_name}  </p>
                            <p>₹ {product.product_price} x {product.quantity} = {product.product_price*product.quantity}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="border-t-4 pt-2">
                {summaryItems.map((item, index) => (
                    <div key={index} className="flex justify-between">
                        <p>{item.label}</p>
                        <p>{item.value}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CartSummary
