import React, { useState, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import SearchComponent from '../Components/Home/SearchComponent'
import QRScannerComponent from '../Components/Home/QRScanner'
import HomeButtons from '../Components/Home/HomeButtons'
import RecentlyVisited from '../Components/Home/RecentlyVisited'

const Home = () => {
    const [searchFocus, setSearchFocus] = useState(false)
    const [showQRScanner, setShowQRScanner] = useState(false)
    const [scannedQRData, setScannedQRData] = useState('')
    const inputRef = useRef(null)

    const handlers = useSwipeable({
        onSwipedRight: () => {
            if (!showQRScanner) {
                setSearchFocus(true)
                inputRef.current.focus()
            }
        },
        onSwipedLeft: () => {
            if (!searchFocus) {
                setShowQRScanner(true)
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    })

    const handleScan = (data) => {
        if (data) {
            console.log('Scanned data:', data.text)
            setScannedQRData(data.text)
            window.open(data.text)
        }
    }

    const handleError = (err) => {
        console.error(err)
    }

    const handleClickSearch = () => {
        setSearchFocus(true)
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }

    const handleClickShowQRScanner = () => {
        setShowQRScanner(true)
    }

    return (
        <div className="bg-[#FFE6FF] min-h-screen p-4">
            <div className="mx-auto max-w-lg mb-4" {...handlers}>
                {!showQRScanner && (
                    <SearchComponent
                        setSearchFocus={setSearchFocus}
                        inputRef={inputRef}
                        searchFocus
                    />
                )}
            </div>

            {!searchFocus && !showQRScanner && (
                <>
                    <HomeButtons
                        onClickSearch={handleClickSearch}
                        onClickShowQRScanner={handleClickShowQRScanner}
                    />
                    <RecentlyVisited />
                </>
            )}

            {showQRScanner && (
                <QRScannerComponent
                    onScan={handleScan}
                    onError={handleError}
                    setShowQRScanner={setShowQRScanner}
                    scannedQRData={scannedQRData} // Pass scanned data yo screen
                />
            )}
        </div>
    )
}

export default Home
