import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../actions';
import { orderApi } from '../services/api';

const PaymentSuccess = () => {
  const orderInfo = useSelector((state) => state.app.orderDetails);
  const [orderDetails, setOrderDetails] = useState(null);
  const [redirectCountdown, setRedirectCountdown] = useState(5); // Initial countdown value
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(orderApi(orderInfo.oid));
        setOrderDetails(response.data.Data);
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    fetchOrderDetails();
  }, [orderInfo.oid]); // Include orderInfo.oid in dependency array

  // Redirect logic when order details are available
  useEffect(() => {
    if (orderDetails) {
      const redirectTimer = setInterval(() => {
        setRedirectCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      const timeout = setTimeout(() => {
        clearInterval(redirectTimer);
        dispatch(setCurrentPage(7)); // Redirect to page 7
      }, redirectCountdown*1000); // Redirect after 10 seconds

      return () => {
        clearInterval(redirectTimer);
        clearTimeout(timeout);
      };
    }
  }, [orderDetails, dispatch]);

  // Render loading spinner while waiting for data
  if (!orderDetails) {
    return (
      <div className="min-h-screen flex flex-col items-center mt-12 bg-pink-100">
        <div className="bg-[#78007a] rounded-full w-24 h-24 flex items-center justify-center mx-auto mb-4">
          <FaCheck className="text-white w-12 h-12" />
        </div>
        <p className="text-gray-700 mt-5 w-[94%]">Loading...</p>
      </div>
    );
  }

  // Once orderDetails is available, render the details
  return (
    <div className="min-h-screen flex flex-col items-center mt-12 bg-pink-100">
      <div className="bg-[#78007a] rounded-full w-24 h-24 flex items-center justify-center mx-auto mb-4">
        <FaCheck className="text-white w-12 h-12" />
      </div>

      <div className="text-center bg-white p-8 rounded-lg shadow-md w-[95%] max-w-md mt-12">
        <p className="text-gray-700 font-bold mb-4 text-xl text-left">Payment Detail</p>
        <div className="text-left text-gray-700 mb-4">
          <div className="flex justify-between">
            <p>Order No.</p>
            <p>{orderDetails.id}</p>
          </div>
          <div className="flex justify-between">
            <p>Total</p>
            <p>₹{orderDetails.amount.toFixed(2)}</p>
          </div>
          <div className="flex justify-between">
            <p>Date & Time</p>
            <p>{new Date(orderDetails.created).toLocaleString()}</p>
          </div>
          <div className="flex justify-between">
              <p>Payment Method</p>
              <p>PHONEPE</p>
            </div>
            <div className="flex justify-between">
              <p>Name</p>
              <p>Guest User</p>
            </div>
            <div className="flex justify-between">
              <p>Email</p>
              <p>guestuser@gmail.com</p>
          </div>
        </div>
      </div>

      <p className="text-gray-700 mt-5 w-[94%]">
        A receipt will be sent directly to the email.
        You will be automatically redirected to the dispense page in <b>{redirectCountdown}s</b>.
      </p>
    </div>
  );
};

export default PaymentSuccess;
