// Action types
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_ID = 'SET_ID'
export const SET_CART = 'SET_CART'
export const SET_REDIRECT = 'SET_REDIRECT'
export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS'
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS'

// Action creators
export const setCurrentPage = (page) => ({
    type: SET_CURRENT_PAGE,
    payload: page,
})

export const setID = (id) => ({
    type: SET_ID,
    payload: id,
})

export const setCart = (cart) => ({
    type: SET_CART,
    payload: cart,
})

export const setRedirect = (url) => ({
    type: SET_REDIRECT,
    payload: url,
})

export const setQueryParams = (params) => ({
    type: SET_QUERY_PARAMS,
    payload: params,
})

export const setOrderDetails = (details) => ({
    type: SET_ORDER_DETAILS,
    payload: details,
})