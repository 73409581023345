import { combineReducers } from 'redux'
import { SET_CURRENT_PAGE, SET_ID, SET_CART, SET_REDIRECT, SET_QUERY_PARAMS, SET_ORDER_DETAILS } from '../actions'

// Initial state
const initialState = {
    currentPage: 0, 
    machineID: null,
    cart: [],
    paymentUrl: "",
    queryParams: "",
    orderDetails: [],
}

// Reducer function
const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            }
        case SET_ID:
            return {
                ...state,
                machineID: action.payload,
            }
        case SET_CART:
            return {
                ...state,
                cart: action.payload,
            }
        case SET_REDIRECT:
            return {
                ...state,
                paymentUrl: action.payload,
            }
        case SET_QUERY_PARAMS:
            return {
                ...state,
                queryParams: action.payload,
            }
        case SET_ORDER_DETAILS:
            return {
                ...state,
                orderDetails: action.payload,
            }
        default:
            return state
    }
}

const rootReducer = combineReducers({
    app: appReducer,
})

export default rootReducer


// currentPage Values and page mappings
// 0 for Home
// 1 for Products
// 2 for Payments Summary (Payment Page)
// 3 for Payments Stats
// 4 for Payment Processing
// 5 for Payment Success
// 6 for Something Went Wrong
// 7 for Dispense Page