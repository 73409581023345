const TotalSummary = ({ cart, total }) => {
    // Convert the values of the cart object into an array of products
    const cartValues = Object.values(cart)

    // Calculate total price using reduce
    const totalPrice = cartValues.reduce(
        (acc, item) => acc + item.product_price * item.quantity,
        0
    )

    // Calculate total quantity by summing up the quantities of all products
    const totalQuantity = cartValues.reduce(
        (acc, item) => acc + item.quantity,
        0
    )
    
    return (
        <div className="w-full flex flex-col justify-center items-center px-4">
            <div className="mt-3 w-60 rounded-xl border-t-4 border-[#78007a]"></div>
            <div className="flex justify-between mt-2 w-full md:w-96">
                <p>Total</p>{' '}
                <span className="text-[#78007A] font-semibold">
                    ₹{total? total : totalPrice}
                </span>
            </div>
            <div className="flex justify-between w-full md:w-96">
                <p>Quantity</p>{' '}
                <span className="text-[#78007A] font-semibold">
                    {totalQuantity} Items
                </span>
            </div>
        </div>
    )
}

export default TotalSummary
