import React from 'react'

const DisplayBox = ({ value, label, wd }) => {
  return (
    <div className={`flex items-center justify-center flex-col w-${wd}`}>
      <div className={`flex items-center justify-center bg-[#78007a] p-2 rounded-lg border-[#78007a] w-${wd} h-24 mx-auto mb-2 mt-4`}>
        <span className="text-black text-4xl font-semibold rounded-lg text-center bg-[#ffe6ff] w-full h-full flex items-center justify-center">{value}</span>
      </div>
      <p className="text-gray-700 text-md">{label}</p>
    </div>
  )
}

export default DisplayBox